.cellTextRight {
    text-align: end;
}

.cell {
    color: black;
    text-align: center;
}

.top3 {
    background-color: rgba(0, 0, 255, 0.48);
    color: black;
    text-align: center;
}

.top10 {
    background-color: rgba(0, 128, 0, 0.25);
    color: black;
    text-align: center;
}

.top20 {
    background-color: rgba(255, 255, 0, 0.71);
    color: black;
    text-align: center;
}

.top50 {
    background-color: rgba(255, 165, 0, 0.52);
    color: black;
    text-align: center;
}

.top100 {
    background-color: rgba(128, 128, 128, 0.71);
    color: black;
    text-align: center;
}



