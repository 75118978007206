.r104f42s7-0-0-beta-37 {
    --rdg-border-color: transparent;
    --rdg-selection-color: transparent;
}

.filterCell {
    line-height: 26px;
    padding: 0;
}

.filterCell select {
    height: 32px;
    width: 100%;
}